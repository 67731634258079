import React from "react";
import images from "../../constants/images";
import icons from "../../constants/icons";
import { useTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const SpeakerSection = () => {
  const { t } = useTranslation("global");

  const data = [
    {
      image: "baghdadi",
      name: "Maitre Mohamed BAGHDADI Bâtonnier de l’ordre des avocats d’Alger",
      role: "Mot De bienvenue",
    },
    {
      image: "person",
      name: "Maître Marc LABBÉ, 1er vice-président de la FBE",
    },
    {
      image: "person",
      name: "Maitre Brahim TAIRI, Président de l’Union Nationale des ordres des avocats d’Algérie",
    },
    {
      image: "person",
      name: "Mot de bienvenue de Monsieur le Ministre de la Justice Garde des Sceaux ou son représentant ",
    },
    {
      image: "person",
      name: "Maitre Yasmina TOUAIBIA",
    },
    {
      image: "person",
      name: "Maitre Amel BENREJDAL",
    },
    {
      image: "person",
      name: "Maître Cem SANAP, du barreau d’Istanbul (Turquie)",
    },
    {
      image: "person",
      name: "Maître Slawomir SZCZERBA, du barreau de Wroclaw (Pologne).",
    },
    {
      image: "person",
      name: "Professeur Mustapha MEKKI Professeur des universités",
    },
    {
      image: "person",
      name: "Madame la Bâtonnière Marie Aimée Peyron",
    },
    {
      image: "person",
      name: "Maître Cem SANAP, du barreau d’Istanbul (Turquie)",
    },
    {
      image: "person",
      name: "Maître Slawomir SZCZERBA, du barreau de Wroclaw (Pologne).",
    },
    {
      image: "person",
      name: "Maitre Nacima LAMALCHI",
    },
    {
      image: "person",
      name: "Maitre Nadjet Saci",
    },
    {
      image: "person",
      name: "Maître Valérie HAYEK",
    },
    {
      image: "person",
      name: "Professeur Mohamed BENTOUMI",
    },
    {
      image: "person",
      name: "Monsieur Boualem IBROUCHENE",
    },
    {
      image: "person",
      name: "Maitre Khalil BENABBAS",
    },
    {
      image: "person",
      name: "Maître Rajko MARIC, ancien Bâtonnier de l’ordre de NoviSad",
    },
    {
      image: "person",
      name: "Maître Laurie ACHTOUK SPIVAK",
    },
    {
      image: "person",
      name: "Maitre Nadia BAKOUR",
    },
    {
      image: "person",
      name: "Professeur Soraya AMRANI MEKKI Professeur des universités ",
    },
    {
      image: "person",
      name: "Maître Khadija AOUDIA Ancienne bâtonnière de Nîmes",
    },
    {
      image: "person",
      name: "Maître Michele CALANTROPO, barreau de Palerme, Président de la commission d’arbitrage de la FBE",
    },
  ];

  const getSpace = (idx) => {
    switch (idx) {
      case 0:
        return "mt-[430px]";
      case 1:
        return "mt-[330px]";
      case 2:
        return "mt-[170px]";
      case 3:
        return "mt-[50px]";
    }
  };

  return (
    <div
      className="mt-16 md:mt-20 h-[700px] md:h-[750px] relative"
      id="speakers-section"
    >
      <div className="absolute left-0 right-0 top-0 bottom-0">
        <div className="md:grid grid-cols-1 md:grid-cols-4 gap-10 hidden -mt-16">
          {data.slice(0, 4).map((item, idx) => (
            <div key={idx} className={`${getSpace(idx)}`}>
              <SpeakerCard item={item} />
            </div>
          ))}
        </div>
        <div className="md:hidden -mt-32">
          {data.slice(0, 1).map((item, idx) => (
            <div
              key={idx}
              className={`${getSpace(idx)}`}
              data-aos="fade-up"
              data-aos-duration={1000 * (idx + 1)}
            >
              <SpeakerCard item={item} />
            </div>
          ))}
        </div>
      </div>
      <div className="relative">
        {/* <h1 className="text-5xl md:text-7xl text-center md:text-left uppercase text-yellow-500 font-extrabold opacity-30">
          {t("speakers.title")}
        </h1> */}
        <div className="absolute left-0 right-0 top-6 md:top-10 md:w-1/2">
          <h2
            data-aos="fade-down"
            className="text-2xl md:text-5xl font-extrabold uppercase md:w-[550px] text-center md:text-left"
          >
            {t("speakers.sub-title")}
            <span className="text-orange-500">
              {" "}
              {t("speakers.sub-title-word")}
            </span>
          </h2>
          <p
            data-aos="fade-down"
            className="font-light mt-6 text-gray-200 text-sm md:text-xl text-center md:text-left"
          >
            {t("speakers.description")}
          </p>
          <div
            data-aos="fade-right"
            className="flex md:block items-center justify-center"
          >
            <button
              className="btn !mt-10 !w-fit bg-orange-500 group !border-none"
              onClick={() => document.getElementById("my_modal_3").showModal()}
            >
              <p className="text-white first-letter:!capitalize group-hover:text-orange-400 !font-bold md:text-lg">
                {t("speakers.more")}
              </p>
            </button>
          </div>
        </div>
      </div>

      <dialog id="my_modal_3" className="modal !cursor-pointer ">
        <div className="modal-box w-11/12 max-w-5xl bg-gray-900 max-h-[1000px]">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg first-letter:capitalize">
            {t("speakers.modal-title")}
          </h3>
          <p className="py-4">{t("speakers.modal-description")}</p>
          <div className="md:grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-6 h-[450px] overflow-y-auto pr-4">
            {data.map((item, idx) => (
              <div key={idx}>
                <SpeakerCard item={item} />
              </div>
            ))}
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default SpeakerSection;

const SpeakerCard = ({ item }) => {
  const { image, name, role } = item;

  return (
    <div className="border-2 border-yellow-500 rounded-3xl h-[350px] overflow-hidden relative group">
      <img src={images[image]} alt="" className="w-full h-full object-cover" />
      <div className="group-hover:block duration-500 ease-in transition-all">
        <div className="absolute left-0 right-0 top-0 bottom-0 b bg-gradient-to-t from-[#000000] group-hover:from-[#eadb0890] to-[#00000090] group-hover:to-[#00000080] h-full">
          <div className="hidden group-hover:flex items-center justify-center flex-col h-full">
            <h4 className="font-semibold text-lg uppercase mb-1 text-center px-2">
              {name}
            </h4>
            {/* <p className="font-light text-sm uppercase mb-3 text-center px-2">
              {role}
            </p> */}
            {/* <div className="flex items-center space-x-2 hidden">
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-full flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-full flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-full flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-full flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
