import React from "react";
import icons from "../constants/icons";

const SocialMedia = () => {
  return (
    <ul className="flex items-center justify-center space-x-3">
      <li className="w-10 h-10 rounded-full bg-orange-500 flex items-center justify-center">
        <img src={icons.linkedin} alt="" className="w-6 h-6 object-cover" />
      </li>
      <li className="w-10 h-10 rounded-full bg-orange-500 flex items-center justify-center">
        <img src={icons.facebook} alt="" className="w-6 h-6 object-cover" />
      </li>
      <li className="w-10 h-10 rounded-full bg-orange-500 flex items-center justify-center">
        <img src={icons.x} alt="" className="w-6 h-6 object-cover" />
      </li>
      <li className="w-10 h-10 rounded-full bg-orange-500 flex items-center justify-center">
        <img src={icons.whatsapp} alt="" className="w-6 h-6 object-cover" />
      </li>
    </ul>
  );
};

export default SocialMedia;
