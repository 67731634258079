import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    item: "",
  },
  reducers: {
    replaceData(state, action) {
      state.item = action.payload;
    },

    clearData(state, action) {
      state.item = [];
    },
  },
});

export const languageActions = languageSlice.actions;

export default languageSlice;
