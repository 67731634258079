import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { languageActions } from "../store/lang-slice";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  const changeLanguage = (lng) => {
    // i18n.changeLanguage(lng);
    // localStorage.setItem("language", lng);
    // dispatch(languageActions.replaceData(lng));
  };

  const lang = useSelector((state) => state.language.item);

  return (
    <div className="md:bg-black py-1">
      <div className="responsive space-x-4 flex items-center md:text-white text-sm">
        {lang !== "en" && (
          <button
            disabled
            onClick={() => changeLanguage("en")}
            className="flex items-center space-x-2"
          >
            <p className="fi fi-us"></p>
            <p className="rtl:pr-2 font-semibold underline">Français</p>
          </button>
        )}
        {lang !== "fr" && (
          <button
            onClick={() => changeLanguage("fr")}
            className="flex items-center space-x-2 rtl:pr-4"
            disabled
          >
            <p className="fi fi-fr"></p>
            <p className="rtl:pr-2">English (coming soon)</p>
          </button>
        )}
        {lang !== "ar" && (
          <button
            disabled
            onClick={() => changeLanguage("ar")}
            className="flex items-center space-x-2 rtl:pr-4"
          >
            <p className="fi fi-dz"></p>
            <p className="rtl:pr-2">(قريبا)العربية</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
