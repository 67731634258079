import React, { useEffect, useState } from "react";

const CounterDown = () => {
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const intervale = setInterval(() => {
      // Define the start and end dates
      const startDate = new Date(); // 10th May
      const endDate = new Date("2024-05-10"); // Current date (25th April)

      // Calculate the difference in milliseconds between the two dates
      const timeDifference = endDate.getTime() - startDate.getTime();

      // Convert milliseconds to days, hours, minutes, and seconds
      const millisecondsPerSecond = 1000;
      const millisecondsPerMinute = millisecondsPerSecond * 60;
      const millisecondsPerHour = millisecondsPerMinute * 60;
      const millisecondsPerDay = millisecondsPerHour * 24;

      // Calculate the number of days
      const days = Math.floor(timeDifference / millisecondsPerDay);
      setDay((d) => days);
      // Calculate the remaining milliseconds after subtracting days
      let remainingMilliseconds = timeDifference % millisecondsPerDay;

      // Calculate the number of hours
      const hours = Math.floor(remainingMilliseconds / millisecondsPerHour);
      setHour((h) => hours);
      // Calculate the remaining milliseconds after subtracting hours
      remainingMilliseconds %= millisecondsPerHour;

      // Calculate the number of minutes
      const minutes = Math.floor(remainingMilliseconds / millisecondsPerMinute);
      setMin((m) => minutes);
      // Calculate the remaining milliseconds after subtracting minutes
      remainingMilliseconds %= millisecondsPerMinute;

      // Calculate the number of seconds
      const seconds = Math.floor(remainingMilliseconds / millisecondsPerSecond);
      setSec((s) => seconds);
    }, 1000);

    return () => {
      clearInterval(intervale);
    };
  }, []);

  return (
    <div className="grid grid-flow-col gap-5 text-center auto-cols-max scale-50 md:scale-100">
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": day }}></span>
        </span>
        days
      </div>
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": hour }}></span>
        </span>
        hours
      </div>
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": min }}></span>
        </span>
        min
      </div>
      <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
        <span className="countdown font-mono text-5xl">
          <span style={{ "--value": sec }}></span>
        </span>
        sec
      </div>
    </div>
  );
};

export default CounterDown;
