import React, { useEffect, useRef, useState } from "react";
/********** Components ****************/
import NavBar from "../templates/NavBar";
import Footer from "../templates/Footer";
import HeroSection from "../Components/sections/HeroSection";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { languageActions } from "../store/lang-slice";
import AboutSection from "../Components/sections/AboutSection";
import JoinUsSection from "../Components/sections/JoinUsSection";
import SpeakerSection from "../Components/sections/SpeakerSection";
import FormulaireSection from "../Components/sections/FormulaireSection";
import ScheduleSection from "../Components/sections/ScheduleSection";
import SponsorSection from "../Components/sections/SponsorSection";
import PricingSection from "../Components/sections/PricingSection";
import NavBarMobile from "../templates/NavBarMobile";

export default function LandingPage(props) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.item);

  const { i18n } = useTranslation();

  const [type, setType] = useState(null);

  const formRef = useRef();

  useEffect(() => {
    const langStorage = localStorage.getItem("language");
    if (langStorage) {
      i18n.changeLanguage(langStorage);
      dispatch(languageActions.replaceData(langStorage));
    }

    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const scrollToChild = (type) => {
    // Access the current property of the childRef to get the DOM element
    if (formRef.current) {
      setType(type);
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      dir={`${lang === "ar" ? "rtl" : "ltr"}`}
      className="bg-gray-900 text-white"
    >
      <div className="hidden md:block">
        <NavBar scrolling={props.scrolling} />
      </div>
      <div className="md:hidden">
        <NavBarMobile scrolling={props.scrolling} fixed />
      </div>

      <HeroSection />

      <div className="responsive">
        <AboutSection />
        <SpeakerSection />
        <ScheduleSection />
        <SponsorSection />
        <PricingSection scrollToChild={scrollToChild} />
        <div ref={formRef}>
          <FormulaireSection type={type} setType={setType} />
        </div>
      </div>

      <Footer />
    </div>
  );
}
