import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import { useDispatch, useSelector } from "react-redux";
import logos from "../constants/logos";

export default function NavBar(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [Active, setActive] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  const lang = useSelector((state) => state.language.item);

  useEffect(() => {
    const item = sessionStorage.getItem("token");
    if (item) {
      setToken(item);
    }
  }, []);

  const dashboard = () => {
    navigate("/dash");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClass = isScrolled || props.fixed ? "fixed-navbar" : "";

  const navigate = useNavigate();

  const { t } = useTranslation("global");

  const changeLanguage = (lng) => {
    t.changeLanguage(lng);
  };

  return (
    <>
      <LanguageSwitcher />
      <div className={`${navbarClass} border-b border-yellow-400 py-3 `}>
        {/* <TopHeader /> */}
        <div className="grid grid-cols-12 responsive bg-transparent">
          <Link to={"/"} className="col-span-2 flex items-center space-x-2">
            <img
              className={`w-20 object-contain`}
              src={logos.logo}
              alt="logo"
            />
          </Link>

          <div className="col-span-10 grid grid-cols-12">
            <div className="col-span-12 hidden md:flex justify-end items-center">
              <ul className="flex justify-end w-full items-center space-x-10  font-medium text-white text-sm">
                <li
                  className={`uppercase cursor-pointer navLinkStyle ${
                    lang === "ar" && "pl-8"
                  }`}
                >
                  <a href="/#home-section">{t("navbar.home")}</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#about-section">{t("navbar.about")}</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#speakers-section">{t("navbar.speakers")}</a>
                </li>

                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href={"/#schedules-section"}>{t("navbar.schedule")}</a>
                </li>
                <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#sponsors-section">{t("navbar.sponsors")}</a>
                </li>
                {/* <li className="uppercase cursor-pointer navLinkStyle">
                  <a href="/#contact-section">{t("navbar.contact")}</a>
                </li> */}
                <li>
                  <a
                    href="/#buy-ticket"
                    className="bg-orange-500 rounded-xl font-medium px-4 py-3 first-letter:capitalize"
                  >
                    {t("navbar.button")}
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="col-span-3 flex items-center justify-end"></div> */}
          </div>
        </div>
      </div>
    </>
  );
}
