import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import images from "../../constants/images";
import CounterDown from "../elements/CounterDown";

import AOS from "aos";
import "aos/dist/aos.css";
import logos from "../../constants/logos";
AOS.init();

export default function HeroSection() {
  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    setPlayer(event.target);
    event.target.playVideo();
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [player, setPlayer] = useState(null);

  const [email, setEmail] = useState("");

  const lang = useSelector((state) => state.language.item);

  useEffect(() => {
    localStorage.removeItem("email");
  }, []);

  const navigate = useNavigate();

  const handleGo = () => {
    localStorage.setItem("email", email);
    navigate("/signup");
  };

  const { t } = useTranslation("global");

  return (
    <div className="relative" id="home-section">
      {/* <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-br from-yellow-400 to-orange-500 bg-red-200 !bg-opacity-15"></div> */}
      <div className="opacity-10">
        <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-t from-yellow-500 to-orange-500"></div>
      </div>
      <img
        src={images.imgHeroSection}
        alt=""
        className="w-full h-screen object-cover"
      />

      <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 responsive">
          <div className="space-y-2 md:space-y-6 flex flex-col justify-center">
            <h4 className="mt-36 md:mt-0 text-white font-medium capitalize text-xs md:text-xl text-center md:text-left">
              {t("hero.sub-title")}
            </h4>
            <h1 className="text-white font-semibold text-xs md:text-4xl lowercase text-center md:text-left">
              {t("hero.title")}
            </h1>
            <button className="btn !my-2 md:mb-0 md:!mt-10 !w-fit mx-auto md:mx-0">
              <a
                href="/#buy-ticket"
                className="text-orange-600 first-letter:!capitalize !font-bold text-xs md:text-lg"
              >
                {t("hero.buy")}
              </a>
            </button>
          </div>
          <div className="border-white border-4 rounded-full p-2 md:p-6 w-[200px] md:w-[500px] h-[200px] md:h-[500px] mx-auto">
            <div className="bg-orange-500 bg-opacity-60 w-full h-full rounded-full flex items-center justify-center flex-col space-y-1 md:space-y-4">
              <p className="font-bold text-4xl md:text-8xl text-white md:-mt-14">
                10
              </p>
              <p className="font-semibold text-lg md:text-2xl uppercase text-white">
                {t("hero.mai")}
              </p>
              <CounterDown />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
