import React, { useEffect, useState } from "react";
import images from "../../constants/images";
import icons from "../../constants/icons";
import {
  CircularProgress,
  CircularProgressLabel,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import emailjs from "emailjs-com";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import axios from "axios";
// import nodemailer from "nodemailer";

const FormulaireSection = ({ type, setType }) => {
  const [msg, setMsg] = useState("");

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [typeAvocat, setTypeAvocat] = useState("algerien");
  const [typeAvocatAlg, setTypeAvocatAlg] = useState("Barreau Alger");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    return null;
    setIsLoading(true);
    axios
      // .post("http://localhost:5000/send-email", {
      .post("https://api.barreaualger-event-dz.com/send-email", {
        nom,
        prenom,
        email,
        tel,
        type: `${
          type === "entreprise"
            ? "entreprise - " + company
            : typeAvocat === "algerien"
            ? `algerien - ${typeAvocatAlg}`
            : typeAvocat
        }`,
        message,
      })
      .then((res) => {
        setMsg("Message envoye");
        setPrenom("");
        setEmail("");
        setTel("");
        setNom("");
        setType("");
        setTypeAvocat("algerien");
        setTypeAvocatAlg("Barreau Alger");
        setMessage("");
        setCompany("");
        toast({
          title: "Confirmation d'Inscription envoyé",
          description:
            "Nous avons envoyé un email pour confirmer votre inscription.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
        toast({
          title: "Error d'Inscription",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
    // emailjs
    //   .sendForm(
    //     "service_1uls6tx",
    //     "template_jicw3gv",
    //     e.target,
    //     "9rGJTdJqD51Xteh9X"
    //   )
    //   .then((result) => {
    //
    //   })
    //   .catch((error) => {
    //     console.log(error.text);
    //     setIsLoading(false);
    //     // Handle error, e.g., show error message
    //   });

    // emailjs
    //   .sendForm(
    //     "service_1uls6tx",
    //     "template_pur793e",
    //     e.target,
    //     "9rGJTdJqD51Xteh9X"
    //   )
    //   .then((result) => {

    //   })
    //   .catch((error) => {
    //     console.log(error.text);
    //     setIsLoading(false);
    //     // Handle error, e.g., show error message
    //   });
  };

  const data = [
    {
      image: "person",
      name: "benrabah mohamed",
      socialMedia: [
        { type: "facebook", link: "https://" },
        { type: "twitter", link: "https://" },
        { type: "linkedin", link: "https://" },
        { type: "instagram", link: "https://" },
      ],
    },
    {
      image: "person",
      name: "benrabah mohamed",
      socialMedia: [
        { type: "facebook", link: "https://" },
        { type: "twitter", link: "https://" },
        { type: "linkedin", link: "https://" },
        { type: "instagram", link: "https://" },
      ],
    },
    {
      image: "person",
      name: "benrabah mohamed",
      socialMedia: [
        { type: "facebook", link: "https://" },
        { type: "twitter", link: "https://" },
        { type: "linkedin", link: "https://" },
        { type: "instagram", link: "https://" },
      ],
    },
    {
      image: "person",
      name: "benrabah mohamed",
      socialMedia: [
        { type: "facebook", link: "https://" },
        { type: "twitter", link: "https://" },
        { type: "linkedin", link: "https://" },
        { type: "instagram", link: "https://" },
      ],
    },
  ];

  const getSpace = (idx) => {
    switch (idx) {
      case 0:
        return "mt-[430px]";
      case 1:
        return "mt-[330px]";
      case 2:
        return "mt-[170px]";
      case 3:
        return "mt-[50px]";
    }
  };

  return (
    <div className="mt-20 h-[1200px] md:h-[750px] relative" id="buy-ticket">
      <div className="relative">
        {/* <h1 className="text-4xl md:text-7xl text-center md:text-left uppercase text-yellow-500 font-extrabold opacity-30">
          Inscrir
        </h1> */}
        <div className="w-full md:absolute left-0 right-0 top-6 md:top-10">
          {/* <h2
            data-oas="fade-right"
            className="text-2xl md:text-5xl text-center md:text-left font-extrabold uppercase md:w-[550px]"
          >
            remplir <span className="text-orange-500">formulaire</span>
          </h2> */}
          <form
            data-aos="fade-up"
            onSubmit={handleSubmit}
            className=" hidden grid-cols-1 md:grid-cols-2 gap-4 mt-10"
          >
            <input type="hidden" name="message" value={message} />
            <input
              type="text"
              name="nom"
              className="text-sm md:text-base border-2 border-yellow-400 rounded-xl px-6 py-3 font-light bg-transparent text-white w-full"
              placeholder="saisir votre nom ..."
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
            <input
              type="text"
              name="prenom"
              className="text-sm md:text-base border-2 border-yellow-400 rounded-xl px-6 py-3 font-light bg-transparent text-white w-full"
              placeholder="saisir votre prenom ..."
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              required
            />
            <input
              type="email"
              name="email"
              className="text-sm md:text-base md:col-span-2 border-2 border-yellow-400 rounded-xl px-6 py-3 font-light bg-transparent text-white w-full"
              placeholder="saisir votre email ..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="tel"
              name="tel"
              className="text-sm md:text-base md:col-span-2 border-2 border-yellow-400 rounded-xl px-6 py-3 font-light bg-transparent text-white w-full"
              placeholder="saisir votre numero de telephone ..."
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              required
            />
            <select
              name="structure"
              id=""
              className="text-sm md:text-base md:col-span-2 border-yellow-400 rounded-xl block px-6 py-3 w-full bg-transparent border-2 text-white"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                if (e.target.value === "entreprise")
                  setMessage(
                    'Afin de confirmer votre participation veuillez vous acquitter d’un montant de 15000 DA payable par virement bancaire au numéro de RIB "00200010100050055666" banque BEA'
                  );
                setCompany("");
                setTypeAvocat("");
              }}
              required
              defaultValue={"selectioner votre structure"}
            >
              <option className="bg-gray-800" value="">
                selectioner votre structure
              </option>
              <option className="bg-gray-800" value="avocat">
                avocat
              </option>
              <option className="bg-gray-800" value="entreprise">
                entreprise
              </option>
            </select>
            {type === "avocat" && (
              <div className="">
                <div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="type"
                      id="t1"
                      value="algerien"
                      className="!w-4"
                      checked={typeAvocat === "algerien"}
                      onChange={(e) => {
                        setTypeAvocat("algerien");
                        setMessage(
                          'Afin de confirmer votre participation veuillez vous acquitter d’un montant de 8000Da payable par virement bancaire au numéro de RIB "00200010100050055666" banque BEA. ou par paiement direct au niveau des salles d’avocats de chaque tribunal de la compétence de la cour d’’Alger'
                        );
                      }}
                    />
                    <label htmlFor="t1">algerien</label>
                  </div>
                  <div>
                    {typeAvocat === "algerien" && (
                      <div className="flex items-center space-x-4 ml-10">
                        <div className="flex items-center space-x-2">
                          <input
                            type="radio"
                            name="type-avocat-alg"
                            id="tt1"
                            value="Barreau Alger"
                            className="!w-4"
                            checked={typeAvocatAlg === "Barreau Alger"}
                            onChange={(e) => {
                              setTypeAvocatAlg("Barreau Alger");
                              setMessage(
                                'Afin de confirmer votre participation veuillez vous acquitter d’un montant de 8000Da payable par virement bancaire au numéro de RIB "00200010100050055666" banque BEA. ou par paiement direct au niveau des salles d’avocats de chaque tribunal de la compétence de la cour d’’Alger'
                              );
                            }}
                          />
                          <label htmlFor="tt1" className="w-[140px]">
                            Barreau Alger
                          </label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <input
                            type="radio"
                            name="type-avocat-alg"
                            id="tt2"
                            value="Autre barreau"
                            className="!w-4"
                            checked={typeAvocatAlg === "Autre barreau"}
                            onChange={(e) => {
                              setTypeAvocatAlg("Autre barreau");
                              setMessage(
                                'Afin de confirmer votre participation veuillez vous acquitter d’un montant de 8000Da payable par virement bancaire au numéro de RIB "00200010100050055666" banque BEA. ou par paiement direct au niveau des salles d’avocats de chaque tribunal de la compétence de la cour d’’Alger'
                              );
                            }}
                          />
                          <label htmlFor="tt2">Autre barreau</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="type"
                    id="t2"
                    value="maghrebin"
                    className="!w-4"
                    checked={typeAvocat === "maghrebin"}
                    onChange={(e) => {
                      setTypeAvocat("maghrebin");
                      setMessage(
                        'Afin de confirmer votre participation veuillez vous acquitter d’un montant de 100€ payable par virement bancaire au numéro de RIB "00200010010300002991" banque BEA'
                      );
                      setTypeAvocatAlg("");
                    }}
                  />
                  <label htmlFor="t2">maghrebin</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="type"
                    id="t3"
                    value="etranger"
                    className="!w-4"
                    checked={typeAvocat === "etranger"}
                    onChange={(e) => {
                      setTypeAvocat("etranger");
                      setMessage(
                        'Afin de confirmer votre participation veuillez vous acquitter d’un montant de 350€ payable par virement bancaire au numéro de RIB "00200010010300002991" banque BEA'
                      );
                      setTypeAvocatAlg("");
                    }}
                  />
                  <label htmlFor="t3">etranger</label>
                </div>
              </div>
            )}

            {type === "entreprise" && (
              <input
                type="text"
                name="company"
                className="text-sm md:text-base md:col-span-2 border-2 border-yellow-400 rounded-xl px-6 py-3 font-light bg-transparent text-white w-full"
                placeholder="saisir nom d'entreprise ..."
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            )}
            {/* <div className="md:col-span-2 border-2 border-yellow-400 font-light p-4 rounded-2xl animate-pulse">
              <h3 className="font-semibold md:text-xl mb-2 ">Note:</h3>
              <p className="text-sm md:text-base">
                RIB DZD: 00200010100050055666
              </p>
              <p className="text-sm md:text-base">
                RIB EUR: 00200010010300002991
              </p>
            </div> */}
            <div className="mx-auto md:mx-0 mb-10 md:mb-0 block md:col-span-2">
              <button
                className="btn !mt-6 !w-fit bg-orange-500 group"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center rounded-md -mt-1 bg-secondary-200 py-4 px-8 w-fit mx-auto bg-primary-100 col-span-2">
                    <Spinner color="white" />
                  </div>
                ) : (
                  <p className="text-sm md:text-base  text-white first-letter:!capitalize group-hover:text-orange-400 !font-bold text-lg">
                    s'inscrir
                  </p>
                )}
              </button>
            </div>
          </form>
        </div>
        <div
          data-aos="zoon-in"
          className="md:absolute right-0 top-20 bottom-0 w-full mt-10"
        >
          <div className="bg-orange-500  rounded-2xl grid grid-cols-5 overflow-hidden px-2">
            <div className="col-span-2 space-y-10 flex items-center justify-center flex-col">
              <div className="flex items-center justify-center flex-col">
                <div className="flex items-center justify-center bg-gray-900 rounded-full w-16 md:w-20 h-16 md:h-20">
                  <img
                    src={icons.location}
                    alt=""
                    className="w-6 md:w-10 h-6 md:h-10 object-cover"
                  />
                </div>
                <h5 className="!text-white uppercase mt-3 text-sm md:text-base">
                  lieu d'evenement:
                </h5>
                <p className="!text-white first-letter:capitalize text-xs md:text-sm font-medium">
                  PALACE OF NATIONS
                </p>
              </div>
              <div className="flex items-center justify-center flex-col">
                <div className="flex items-center justify-center bg-gray-900 rounded-full w-16 md:w-20 h-16 md:h-20">
                  <img
                    src={icons.head}
                    alt=""
                    className="w-6 md:w-10 h-6 md:h-10 object-cover"
                  />
                </div>
                <h5 className="!text-white uppercase mt-3 text-sm md:text-base">
                  contact info:
                </h5>
                <a
                  href="mailto:barreaualger-event-dz.com"
                  className="!text-white first-letter:capitalize text-xs font-medium text-center"
                >
                  contact@barreaualger-event-dz.com
                </a>
                <a
                  href="tel:00213661808015"
                  className="!text-white first-letter:capitalize text-xs border-t pt-1 mt-1 font-medium text-center"
                >
                  +213 661 808 015
                </a>
              </div>
            </div>
            <div className="col-span-3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12784.065470916497!2d2.8853421!3d36.7701748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fbb17b451e753%3A0x4262ae8c3b51dee!2sPALACE%20OF%20NATIONS!5e0!3m2!1sen!2sdz!4v1714296098632!5m2!1sen!2sdz"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormulaireSection;

const FormulaireCard = ({ item }) => {
  const { image, name, socialMedia } = item;

  return (
    <div className="border-2 border-yellow-500 rounded-3xl h-[350px] overflow-hidden relative group">
      <img src={images[image]} alt="" className="w-full h-full object-cover" />
      <div className="hidden group-hover:block duration-500 ease-in transition-all">
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-t from-[#eadb0890] to-[#00000090] h-full">
          <div className="flex items-center justify-center flex-col h-full">
            <h4 className="font-semibold text-lg uppercase mb-1">{name}</h4>
            <p className="font-light text-sm uppercase mb-3">
              event Formulaire
            </p>
            <div className="flex items-center space-x-2">
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-xl flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-xl flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-xl flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
              <a
                href={socialMedia[0].link}
                className="bg-orange-400 rounded-xl flex items-center justify-center w-8 h-8 overflow-hidden"
              >
                <img src={""} alt="" className="w-4 object-cover" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
