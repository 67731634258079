import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"; // Replace with the specific icon you want to use
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import logos from "../constants/logos";

export default function NavBarMobile(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [Active, setActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClass = isScrolled || props.fixed ? "fixed-navbar" : "";

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  useEffect(() => {
    onClose();
  }, [window.location.href]);

  const { t } = useTranslation("global");
  return (
    <>
      <div className={`${navbarClass} border-b py-2`}>
        {/* <TopHeader /> */}
        <div className="flex items-center justify-between responsive bg-gray-900 shadow-md">
          <Link to={"/"} className="col-span-3 flex items-center space-x-2">
            <img
              className={`w-20 object-contain`}
              src={logos.logo}
              alt="logo"
            />
          </Link>

          <button ref={btnRef} colorScheme="teal" onClick={onOpen}>
            <FontAwesomeIcon
              className="pr-2"
              style={{ color: "#4ac4f3" }}
              icon={faBars}
            />
          </button>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size={"sm"}
          >
            <DrawerOverlay />
            <DrawerContent className="!bg-gray-900 !text-white">
              <DrawerCloseButton />
              <DrawerHeader>Menu</DrawerHeader>

              <DrawerBody>
                <ul className="w-full space-y-4 font-medium text-white text-sm">
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="/#home-section">{t("navbar.home")}</a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="/#about-section">{t("navbar.about")}</a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="/#speakers-section">{t("navbar.speakers")}</a>
                  </li>

                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href={"/#schedules-section"}>{t("navbar.schedule")}</a>
                  </li>
                  <li className="uppercase cursor-pointer navLinkStyle">
                    <a href="/#sponsors-section">{t("navbar.sponsors")}</a>
                  </li>
                </ul>
              </DrawerBody>

              <DrawerFooter className="!w-full">
                <div className="w-full mt-10 border-t border-gray-300 pt-4">
                  <LanguageSwitcher />
                </div>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </>
  );
}
