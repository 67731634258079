import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import images from "../../constants/images";
import CounterDown from "../elements/CounterDown";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function AboutSection() {
  const { t } = useTranslation("global");

  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 gap-20 pt-16 md:pt-28 h-[500px] md:h-[600px]"
      id="about-section"
    >
      <div className="relative hidden md:flex items-center justify-self-center">
        <div
          data-aos="zoon-in"
          className="w-[250px] h-[350px] border-4 border-yellow-400 rounded-3xl overflow-hidden"
        >
          <img
            src={images.avocat1}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div
          data-aos="zoon-in"
          className="absolute -left-24 top-0 w-[150px] h-[200px] border-8 border-gray-900 rounded-3xl overflow-hidden"
        >
          <img
            src={images.avocat2}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div
          data-aos="zoom-in"
          className="absolute -right-32 bottom-0 w-[200px] h-[200px] border-8 border-gray-900 rounded-3xl overflow-hidden"
        >
          <img
            src={images.avocat2}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div
          data-aos="zoom-in"
          className="absolute -right-32 top-0 w-[100px] h-[100px] border-4 border-yellow-400 rounded-3xl overflow-hidden"
        >
          <img
            src={images.avocat2}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div
          data-aos="zoom-in"
          className="absolute -left-40 bottom-32 w-[130px] h-[100px] border-4 border-yellow-400 rounded-3xl overflow-hidden"
        >
          <img
            src={images.avocat1}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="relative w-full overflow-x-hidden">
        {/* <h1 className="text-4xl text-center md:text-left md:text-7xl uppercase text-yellow-500 font-extrabold opacity-30">
          {t("about.title")}
        </h1> */}
        <div
          data-aos="fade-left"
          className="absolute left-0 right-0 top-6 md:top-10"
        >
          <h2 className="text-center md:text-left text-2xl md:text-5xl font-extrabold uppercase">
            {t("about.sub-title")}
            <span className="text-orange-500">
              {" "}
              {t("about.sub-title-word")}
            </span>
          </h2>
          <p className=" font-light mt-4 md:mt-10 text-gray-200 text-sm md:text-xl text-center md:text-left">
            {t("about.description")}
          </p>
          {/* <div className="flex flex-col md:flex-row items-center space-x-4 mt-6">
            <img
              src={images.person}
              alt=""
              className="w-14 h-14 rounded-full object-cover"
            />
            <div>
              <h4 className="font-semibold md:text-xl uppercase mt-2 md:mt-0 text-center md:text-left">
                {t("about.name")}
              </h4>
              <p className="uppercase font-light text-xs md:text-sm text-center md:text-left">
                {" "}
                {t("about.role")}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
