import experience from "../assets/icons/experience.png";
import check from "../assets/icons/check_circle_FILL0_wght400_GRAD0_opsz24.svg";
import head from "../assets/icons/headset_mic_FILL0_wght400_GRAD0_opsz24.svg";
import location from "../assets/icons/location_on_FILL0_wght400_GRAD0_opsz24.svg";
import whatsapp from "../assets/icons/icons8-whatsapp-30.png";
import linkedin from "../assets/icons/icons8-linkedin-50.png";
import facebook from "../assets/icons/icons8-facebook-30.png";
import x from "../assets/icons/icons8-x-30.png";

export default {
  location,
  head,
  check,
  experience,
  facebook,
  whatsapp,
  linkedin,
  x,
};
