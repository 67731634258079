import React from "react";
import icons from "../../constants/icons";
import { useTranslation } from "react-i18next";

const ScheduleSection = () => {
  const { t } = useTranslation("global");

  const data = [
    {
      title: "best experience speakers",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, assumenda. Vel quis non ea illo asperiores magni do minus architecto.",
      icon: "experience",
    },
    {
      title: "best experience speakers",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, assumenda. Vel quis non ea illo asperiores magni do minus architecto.",
      icon: "experience",
    },
    {
      title: "best experience speakers",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, assumenda. Vel quis non ea illo asperiores magni do minus architecto.",
      icon: "experience",
    },
    {
      title: "best experience speakers",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, assumenda. Vel quis non ea illo asperiores magni do minus architecto.",
      icon: "experience",
    },
  ];

  return (
    <div className="mt-14 md:mt-28" id="schedules-section">
      <div className="flex items-center justify-between">
        <div className="relative w-full">
          {/* <h1 className="text-4xl md:text-7xl  text-center md:text-left w-full uppercase text-yellow-500 font-extrabold opacity-30">
            {t("schedule.title")}
          </h1> */}
          <div
            data-aos="fade-right"
            className="absolute left-0 right-0 top-6 md:top-0 flex justify-center md:justify-start"
          >
            <h2 className="text-2xl md:text-5xl text-center md:text-left font-extrabold uppercase w-[600px]">
              {t("schedule.subTitle")}{" "}
              <span className="text-orange-500">
                {t("schedule.subTitle-word")}{" "}
              </span>
              {/* {t("schedule.subTitle2")}{" "} */}
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-44 md:mt-44 grid grid-cols-1 md:grid-cols-2 md:gap-20">
        <div data-aos="fade-up" className="space-y-4 md:space-y-6">
          <div className="bg-orange-400 bg-opacity-30 rounded-full h-14 w-[250px] md:w-[300px] relative mx-auto">
            <div className="absolute -left-2 -bottom-2 border-2 border-orange-500 h-full w-full flex items-center justify-center rounded-full bg-gray-900 uppercase z-20">
              <p className="text-sm md:text-base">
                {t("schedule.day")} 1:{" "}
                <span className="font-light text-gray-300 ml-2">
                  {t("schedule.date-1")}
                </span>
              </p>
            </div>
            <div className="absolute -bottom-5 left-1/2 -translate-x-1/2 -ml-2 w-6 h-6 rotate-45 bg-orange-500 bg-opacity-30 z-10"></div>
          </div>
          <div className="pt-4"></div>
          {[2, 3].map((item, idx) => (
            <BoxInfo
              key={idx}
              item={{
                title: t(`schedule.title-${item}`),
                hour: t(`schedule.hour-${item}`),
                speaker: t(`schedule.speaker-${item}`),
                lecture: t("schedule.lecture") + " " + item,
              }}
            />
          ))}
        </div>
        <div data-aos="fade-up" className="space-y-6 hidden md:block">
          <div className="bg-orange-400 bg-opacity-30 rounded-full h-14 w-[300px] relative mx-auto">
            <div className="absolute -left-2 -bottom-2 border-2 border-orange-500 h-full w-full flex items-center justify-center rounded-full bg-gray-900 uppercase z-20">
              <p>
                {t("schedule.day")} 2:{" "}
                <span className="font-light text-gray-300 ml-2">
                  {t("schedule.date-2")}
                </span>
              </p>
            </div>
            <div className="absolute -bottom-5 left-1/2 -translate-x-1/2 -ml-2 w-6 h-6 rotate-45 bg-orange-500 bg-opacity-30 z-10"></div>
          </div>
          <div className="pt-4"></div>
          {[10, 11].map((item, idx) => (
            <BoxInfo
              key={idx}
              item={{
                title: t(`schedule.title-${item}`),
                hour: t(`schedule.hour-${item}`),
                speaker: t(`schedule.speaker-${item}`),
                lecture: t("schedule.lecture") + " " + item,
              }}
            />
          ))}
        </div>
        <button
          data-aos="zoom-in"
          onClick={() => document.getElementById("my_modal_4").showModal()}
          className="mt-4 md:mt-0 text-sm md:text-base col-span-2 rounded-xl text-white font-semibold first-letter:capitalize bg-orange-500 py-3 w-fit px-6 mx-auto"
        >
          {t("schedule.discover")}
        </button>
      </div>

      <dialog id="my_modal_4" className="modal !cursor-pointer ">
        <div className="modal-box w-11/12 max-w-5xl bg-gray-900 max-h-[1000px]">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg first-letter:capitalize">
            {t("schedule.modal-title")}
          </h3>
          <p className="py-4">{t("schedule.modal-description")}</p>
          <div className="mt-0 grid grid-cols-1 md:grid-cols-2 md:gap-10 h-[450px] overflow-y-auto pr-4">
            <div className="space-y-4 md:space-y-6 mt-4 md:mt-0">
              <div className="bg-orange-400 bg-opacity-30 rounded-full h-14 w-[250px] md:w-[300px] relative mx-auto">
                <div className="absolute -left-2 -bottom-2 border-2 border-orange-500 h-full w-full flex items-center justify-center rounded-full bg-gray-900 uppercase z-20">
                  <p className="text-sm md:text-base">
                    {t("schedule.day")} 1:{" "}
                    <span className="font-light text-gray-300 ml-2">
                      {t("schedule.date-1")}
                    </span>
                  </p>
                </div>
                <div className="absolute -bottom-5 left-1/2 -translate-x-1/2 -ml-2 w-6 h-6 rotate-45 bg-orange-500 bg-opacity-30 z-10"></div>
              </div>
              <div className="pt-4"></div>
              {[
                2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23, 24,
              ].map((item, idx) => (
                <BoxInfo
                  key={idx}
                  item={{
                    title: t(`schedule.title-${item}`),
                    hour: t(`schedule.hour-${item}`),
                    speaker: t(`schedule.speaker-${item}`),
                    lecture: t("schedule.lecture") + " " + item,
                  }}
                />
              ))}
            </div>
            <div className="space-y-6  mt-4 md:mt-0">
              <div className="bg-orange-400 bg-opacity-30 rounded-full h-14 w-[300px] relative mx-auto">
                <div className="absolute -left-2 -bottom-2 border-2 border-orange-500 h-full w-full flex items-center justify-center rounded-full bg-gray-900 uppercase z-20">
                  <p>
                    {t("schedule.day")} 2:{" "}
                    <span className="font-light text-gray-300 ml-2">
                      {t("schedule.date-2")}
                    </span>
                  </p>
                </div>
                <div className="absolute -bottom-5 left-1/2 -translate-x-1/2 -ml-2 w-6 h-6 rotate-45 bg-orange-500 bg-opacity-30 z-10"></div>
              </div>
              <div className="pt-4"></div>
              {[25, 26, 28, 29, 30, 31, 32, 33, 34, 35, 36].map((item, idx) => (
                <BoxInfo
                  key={idx}
                  item={{
                    title: t(`schedule.title-${item}`),
                    hour: t(`schedule.hour-${item}`),
                    speaker: t(`schedule.speaker-${item}`),
                    lecture: t("schedule.lecture") + " " + item,
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default ScheduleSection;

const BoxInfo = ({ item }) => {
  const { title, speaker, hour, lecture } = item;

  return (
    <div className="bg-gray-950 bg-opacity-40 rounded-3xl h-fit md:h-[200px] grid grid-cols-1 md:grid-cols-6 overflow-hidden">
      <div className="bg-orange-400 bg-opacity-60 flex items-center justify-center flex-col col-span-2 py-4 md:py-0">
        {/* <p className="font-light uppercase text-sm">{lecture}</p> */}
        <p className="md:text-base font-bold text-sm text-center">{hour}</p>
      </div>
      <div className="col-span-4 flex flex-col justify-center px-4 space-y-2 py-4 md:py-0">
        {speaker && (
          <h3 className="font-semibold text-xs md:text-sm first-letter:capitalize">
            {speaker}
          </h3>
        )}
        <p className="font-light text-gray-200 first-letter:capitalize text-xs md:text-sm">
          {title}
        </p>
      </div>
    </div>
  );
};
