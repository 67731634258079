import React from "react";
import icons from "../../constants/icons";
import { useTranslation } from "react-i18next";
import images from "../../constants/images";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const PricingSection = ({ scrollToChild }) => {
  const { t } = useTranslation("global");

  const data = [
    {
      type: t("pricing.avocat"),
      description: t("pricing.avocat-description"),
      price: t("pricing.avocat-price"),
    },
    {
      type: t("pricing.entreprise"),
      description: t("pricing.entreprise-description"),
      price: t("pricing.entreprise-price"),
    },
    {
      type: t("pricing.euro"),
      description: t("pricing.euro-description"),
      price: t("pricing.euro-price"),
    },
    {
      type: t("pricing.euro2"),
      description: t("pricing.euro2-description"),
      price: t("pricing.euro2-price"),
    },
  ];

  return (
    <div className="mt-28" id="pricing-section">
      <div className="flex flex-col-reverse items-center justify-between w-full overflow-x-hidden">
        <div className="pt-10 mt-20 md:mt-0 md:w-full grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
          {data.map((item, idx) => (
            <div
              data-aos="fade-up"
              data-aos-duration={1000 * (idx + 1)}
              key={idx}
              className={`${"!scale-y-100"}`}
            >
              <BoxInfo
                item={item}
                scrollToChild={() => scrollToChild(item.type)}
              />
            </div>
          ))}
        </div>
        <div className="relative w-full">
          {/* <h1
            data-aos="fade-left"
            className="text-4xl md:text-6xl text-center md:text-left uppercase text-yellow-500 font-extrabold opacity-30"
          >
            {t("pricing.title")}
          </h1> */}
          <div
            data-aos="fade-left"
            className="absolute left-0 right-0 top-6 md:top-10"
          >
            <h2 className="text-2xl md:text-5xl text-center md:text-left font-extrabold uppercase md:w-[500px]">
              {t("pricing.sub-title")}
              <span className="text-orange-500">
                {" "}
                {t("pricing.sub-title-word")}
              </span>
            </h2>
          </div>
          <p
            data-aos="fade-left"
            className="font-light mt-20 md:mt-24 text-gray-200 text-sm md:text-xl md:w-[500px] text-center md:text-left"
          >
            {t("pricing.description")}
          </p>
          <div
            data-aos="zoom-in"
            className="bg-yellow-400 bg-opacity-30 rounded-2xl h-[200px] md:h-[120px] w-[90%] mt-14 relative ml-4"
          >
            <div className="absolute -left-3 -top-3 w-full h-full bg-gray-900 rounded-2xl border-2 border-yellow-500 p-4">
              <p className="uppercase font-medium mb-2">{t("pricing.what")}:</p>
              <ul>
                <li className="!h-fit md:!h-8 flex items-center space-x-2 text-sm">
                  <img src={icons.check} alt="" className="w-4" />
                  <p className="text-xs">{t("pricing.what-1")}</p>
                </li>
                <li className="mt-4 md:mt-0 !h-fit md:!h-8 flex items-center space-x-2 text-sm">
                  <img src={icons.check} alt="" className="w-4" />
                  <p className="text-xs">{t("pricing.what-2")}</p>
                </li>
                {/* <li className="!h-8 flex items-center space-x-2 text-sm">
                  <img src={icons.check} alt="" className="w-4" />
                  <p className="text-xs">{t("pricing.what-3")}</p>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;

const BoxInfo = ({ item, scrollToChild }) => {
  const { type, price, description } = item;
  const { t } = useTranslation("global");

  return (
    <div className="relative">
      <div className="absolute -top-3 left-3 right-3 flex items-center justify-between">
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <div key={item} className="w-6 h-6 rounded-full bg-gray-900"></div>
        ))}
      </div>
      <div className="absolute -bottom-3 left-3 right-3 flex items-center justify-between">
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <div key={item} className="w-6 h-6 rounded-full bg-gray-900"></div>
        ))}
      </div>
      <div className="bg-orange-500 text-white px-4 py-5 ">
        <h5 className="text-white font-bold text-xs md:text-sm uppercase text-center">
          {type}
        </h5>
        <p className="text-center text-3xl md:text-3xl font-bold text-white mt-1">
          {price}
        </p>
      </div>
      <div className="h-[260px] text-sm border-l-4 border-r-4 border-dashed border-orange-500 px-4 py-6">
        <p>{description}</p>
      </div>
      <div className="bg-white px-4 py-5 flex items-center justify-center w-full h-[60px]">
        {/* <button
          className="text-sm md:text-base bg-orange-500 px-4 py-3 text-white uppercase rounded-xl"
          onClick={scrollToChild}
        >
          {t("pricing.buy")}
        </button> */}
      </div>
    </div>
  );
};
