import React from "react";
import icons from "../../constants/icons";
import { useTranslation } from "react-i18next";
import images from "../../constants/images";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const SponsorSection = () => {
  const { t } = useTranslation("global");

  const data = [
    {
      title: t("join.title-1"),
      description: t("join.description-1"),
      icon: "experience",
    },
    {
      title: t("join.title-2"),
      description: t("join.description-2"),
      icon: "experience",
    },
    {
      title: t("join.title-3"),
      description: t("join.description-3"),
      icon: "experience",
    },
  ];

  return (
    <div className="mt-16 md:mt-28 w-full" id="sponsors-section">
      <div className="flex items-center justify-between w-full">
        <div className="relative">
          {/* <h1 className="text-4xl md:text-7xl text-center md:text-left uppercase text-yellow-500 font-extrabold opacity-30">
            {t("sponsors.title")}
          </h1> */}
          <div className="absolute left-0 right-0 top-6 md:top-0">
            <h2
              data-aos="fade-right"
              className="text-2xl md:text-5xl text-center md:text-left font-extrabold uppercase md:w-[500px]"
            >
              {t("sponsors.sub-title")}
              <span className="text-orange-500">
                {" "}
                {t("sponsors.sub-title-word")}
              </span>
            </h2>
          </div>
          <p
            data-aos="fade-right"
            className="font-light mt-32 md:mt-32 text-gray-200 text-center md:text-left text-sm md:text-xl md:w-[500px]"
          >
            {t("sponsors.description")}
          </p>
        </div>
      </div>
      <div data-aos="fade-right" className="mt-16 flex items-center space-x-10">
        <div className=" relative flex items-center justify-center px-4 py-6 w-[100px] md:w-[200px] first-letter:capitalize h-[80px] md:h-[110px]">
          <div className="absolute -right-3 top-1/2 -translate-y-1/2 w-6 h-6 bg-yellow-500 bg-opacity-30 rotate-45"></div>
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-gray-900 border-2 border-yellow-500 border-opacity-30 rounded-3xl flex items-center justify-center">
            <p className="md:text-2xl text-yellow-500 font-bold text-center uppercase text-xs">
              {t("sponsors.gold")}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-6">
          {data.map((item, idx) => (
            <img
              key={idx}
              src={images.person}
              className="w-8 md:w-20 h-8 md:h-20"
            />
          ))}
        </div>
      </div>
      <div data-aos="fade-right" className="mt-10 flex items-center space-x-10">
        <div className=" relative flex items-center justify-center px-4 py-6 w-[100px] md:w-[200px] first-letter:capitalize h-[80px] md:h-[110px]">
          <div className="absolute -right-3 top-1/2 -translate-y-1/2 w-6 h-6 bg-gray-400 bg-opacity-30 rotate-45"></div>
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-gray-900 border-2 border-gray-200 border-opacity-30 rounded-3xl flex items-center justify-center">
            <p className="text-xs md:text-2xl text-gray-400 font-bold text-center uppercase">
              {t("sponsors.silver")}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-6">
          {data.map((item, idx) => (
            <img
              key={idx}
              src={images.person}
              className="w-8 md:w-20 h-8 md:h-20"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SponsorSection;

const BoxInfo = ({ item }) => {
  const { title, description, icon } = item;

  return (
    <div className="bg-orange-300 bg-opacity-40 rounded-3xl relative w-full h-[300px]">
      <div className="absolute -left-3 -top-3 border-2 border-orange-500 w-full h-full rounded-3xl p-8 bg-gray-900 flex items-center justify-center space-x-4">
        <div className="w-full">
          <h2 className="mb-4 font-bold text-2xl uppercase">{title}</h2>
          <p className="font-light">{description}</p>
        </div>
        <img src={icons[icon]} alt="" className="!w-32 h-32 object-cover" />
      </div>
    </div>
  );
};
