import React from "react";
import { useTranslation } from "react-i18next";
import images from "../constants/images";
import SocialMedia from "./SocialMedia";

import AOS from "aos";
import "aos/dist/aos.css";
import logos from "../constants/logos";
AOS.init();

export default function Footer() {
  const { t } = useTranslation("global");
  return (
    <div
      id="footer-section"
      className="flex items-center flex-col justify-center"
    >
      <img src={logos.logo} alt="" className="w-44" />
      <p className="text-center w-3/4 font-light mt-4 text-sm md:text-base">
        {t("footer.description")}
      </p>
      <div className="my-4">
        <SocialMedia />
      </div>
      <div className="border-t border-gray-500 w-full py-4 mt-4">
        <p className="font-light text-xs md:text-sm text-center">
          {t("footer.copy-right")}
        </p>
      </div>
    </div>
  );
}
